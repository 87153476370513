import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";

export const state = {
  events: [],
  event: {
    id: null,
    performerId: null,
    performerName: "",
    name: "",
    description: "",
    venue: "",
    step: 1,
    stepsCompleted: 0,
    minPrice: 0,
    maxPrice: 0,
    startDate: new Date(),
  },
  priceTiers: [],
  eventDates: [],
};

export const mutations = {
  SET_EVENTS(state, events) {
    state.events = events;
  },
  SET_EVENT(state, event) {
    state.event = event;
  },
  SET_PRICE_TIERS(state, tiers) {
    console.log("Setting tiers ", tiers.length);
    state.priceTiers = tiers;
  },
  SET_EVENT_DATES(state, dates) {
    console.log("Setting dates ", dates.length);
    state.eventDates = sortDates(dates);
  },
};

export const actions = {
  async fetchEvent({ commit }, eventId) {
    var db = firebase.firestore();
    var docRef = db.collection("events").doc(eventId);
    var doc = await docRef.get();

    if (doc.exists) {
      console.log("Found the user profile.");
      var event = doc.data();
      event.id = docRef.id;
      commit("SET_EVENT", event);
    } else {
      console.log("Could not find event " + eventId);
    }
  },
  async fetchEvents({ commit }) {
    var db = firebase.firestore();
    var docRef = db.collection("events");
    var docs = await docRef
      .where("status", "==", "published")
      .where("isPublic", "==", true)
      .orderBy("start")
      .get();

    if (!docs.empty) {
      console.log("Found the events.");
      commit(
        "SET_EVENTS",
        docs.docs.map((doc) => doc.data())
      );
    } else {
      console.log("Could not find event ");
    }
  },
  async fetchArtistEvents({ commit }, artistId) {
    var db = firebase.firestore();
    var docRef = db.collection("events");
    var docs = await docRef
      .where("actId", "==", artistId)
      .where("status", "==", "published")
      .where("isPublic", "==", true)
      .orderBy("start")
      .get();

    if (!docs.empty) {
      console.log("Found the events.");
      commit(
        "SET_EVENTS",
        docs.docs.map((doc) => doc.data())
      );
    } else {
      console.log("Could not find event ");
    }
  },
  async fetchPriceTiers({ commit }) {
    console.log("id? ", state.event.id);
    var db = firebase.firestore();
    var docRef = db
      .collection("events")
      .doc(state.event.id)
      .collection("priceTiers");
    var tiers = await docRef.get();
    if (!tiers.empty) {
      console.log("Found the price tiers.", tiers);
      commit(
        "SET_PRICE_TIERS",
        tiers.docs.map((doc) => {
          var tier = doc.data();
          tier.quantity = 0;
          return tier;
        })
      );
    } else {
      console.log("Could not find any price tiers.");
    }
  },
  async fetchFestivalEvents({ commit }, seasonId) {
    var db = firebase.firestore();
    var events = await db
      .collection("events")
      .where("seasonId", "==", seasonId)
      .where("isPublic", "==", true)
      .orderBy("start")
      .get();

    if (!events.empty) {
      commit(
        "SET_EVENTS",
        events.docs.map((doc) => doc.data())
      );
    } else {
      console.log("Could not find any events for this festival");
    }
  },
  async fetchEventDates({ commit }) {
    var db = firebase.firestore();
    let timestamp = new Date(Date.now());
    console.log("time now", timestamp);
    var docRef = db
      .collection("events")
      .doc(state.event.id)
      .collection("dates");
    var docs = await docRef.get();

    if (!docs.empty) {
      console.log("Found the events.");
      commit(
        "SET_EVENT_DATES",
        docs.docs.map((doc) => {
          var ed = doc.data();
          if (!ed.id) ed.id = doc.id;
          return ed;
        })
      );
    } else {
      console.log("Could not find event ");
    }
  },
};

export const getters = {
  event(state) {
    return state.event;
  },
  events(state) {
    return state.events;
  },
  priceTiers(state) {
    return state.priceTiers;
  },
  eventDates(state) {
    return state.eventDates;
  },
};

function sortDates(dates) {
  dates.sort(function (a, b) {
    return (
      moment(a.startDate + " " + a.startTime, "YYYY-MM-DD hh:mm A").toDate() -
      moment(b.startDate + " " + b.startTime, "YYYY-MM-DD hh:mm A").toDate()
    );
  });
  return dates;
}
